<template>
  <div>
    <v-card flat :loading="loading">
      <v-card-text>

        <div class="text-center mt-5">

          <v-avatar size="80" v-if="$store.state.user.user.account.photo">
            <v-img :src="getImageLink($store.state.user.user.account.photo)"></v-img>
          </v-avatar>

          <v-avatar size="60" color="gifty" v-else>
            <v-icon color="white">mdi-storefront-outline</v-icon>
          </v-avatar>

          <h3 class="pt-3 mb-2 font-weight-medium">
            {{ $store.state.user.user.name }}
          </h3>

          <p class="pb-8">
            {{ $store.state.user.user.phone }}
          </p>

        </div>


        <v-list nav rounded>

          <v-list-item :to="'/partner-vouchers/store/'+$route.params.id"
                       color="primary">
            <v-list-item-avatar>
              <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Liste des scans</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item to="/myAccount"
                       color="primary">
            <v-list-item-avatar>
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Mon compte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item color="primary"
                       exact
                       @click="logout">
            <v-list-item-avatar>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Déconnexion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>


      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      loading: false
    }
  },
  methods: {
    logout() {
      this.loading = true
      HTTP.post('auth/logout').then(() => {
        this.loading = false
        this.$store.dispatch('user', '')
        this.$store.dispatch('isLoggedIn', false)
        this.$router.go(0)
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style>
.chip-success {
  background: #79d70f21 !important;
  color: #79d70f !important;
}
</style>